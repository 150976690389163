<template>
<div style="width:100%">
  <v-dialog v-model="dialogo" fullscreen persistent transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark :color="colores.secundario">
        <v-btn icon dark @click="cerrarVenta"><v-icon>mdi-close</v-icon></v-btn>
        <v-toolbar-title>PQR</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pt-4">
        <lectura :gestion_id="gestion_id" :session="session" :token="token" :colores="colores" :items="items" :cerrar="cerrar"></lectura>
      </v-card-text>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
// import lectura from '@/components/crm/lecturaComponent.vue'
export default {
  name: 'crm_lecturaComponent',
  components: {
    lectura: () => import('@/components/crm/lecturaComponent.vue')
  },
  props: ['colores', 'dialogo', 'items', 'token', 'gestion_id', 'session'],
  data: () => ({
    cerrar: false
  }),
  created () {
  },
  methods: {
    cerrarVenta () {
      this.cerrar = true
      setTimeout(() => {
        this.cerrar = false
      }, 3000)
      this.$emit('closed_lectura')
    }
  }
}
</script>
